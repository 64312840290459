import React, { lazy } from "react";

const Greetings = lazy(() => import("../src/containers/Greetings"));
const GithubProfileCard = lazy(() => import("./components/GithubProfileCard"));
const Navigation = lazy(() => import("./components/Navigation"));
const Skills = lazy(() => import("./containers/Skills"));
const Projects = lazy(() => import("./containers/Projects"));

function App() {
  return (
    <div>
      <Navigation />
      <Greetings />
      <Skills />
      <Projects />
      <GithubProfileCard />
    </div>
  );
}

export default App;
