import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/styles/argon-design-system-react.css";
import "../src/styles/styles.css";
import "../src/styles/vendor/font-awesome/css/font-awesome.min.css";
import "../src/styles/vendor/nucleo/css/nucleo.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
